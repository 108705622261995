import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";
import { mobile } from "clutch/src/Style/style.mjs";

import { readState } from "@/__main__/app-state.mjs";
import ChampionImg from "@/game-lol/components/ChampionImg.jsx";
import {
  ROLE_INDEX,
  ROLE_SYMBOL_TO_STR,
  ROLE_SYMBOLS,
} from "@/game-lol/constants/constants.mjs";
import lolRefs from "@/game-lol/refs.mjs";
import getRoleIcon from "@/game-lol/utils/get-role-icon.mjs";
import RoleSymbol from "@/game-lol/utils/symbol-role.mjs";
import {
  getDefaultedFiltersForChampions,
  getSearchParamsForChampions,
  getStaticData,
} from "@/game-lol/utils/util.mjs";
import getTierIcon from "@/shared/get-tier-icon.mjs";
import { calcRate } from "@/util/helpers.mjs";
import { getLocaleString } from "@/util/i18n-helper.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const percentOptions = {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
  style: "percent",
};

const commonColumnCss = `
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: var(--shade1);
  font-size: var(--sp-3_5);
`;

// TODO (Rio): ZzZzZzZz these need to be removed
export const cssRank = () => css`
  color: var(--shade0);
`;
export const cssChampionName = () => css`
  ${commonColumnCss};
  justify-content: flex-start;
  color: var(--shade0);
  padding-inline: var(--sp-2);

  .championKey {
    display: flex;
    align-items: center;
    gap: var(--sp-4);

    ${mobile} {
      gap: var(--sp-3);
    }
  }

  span {
    color: var(--shade0);
  }
`;

function usePatchStats(patch) {
  const {
    lol: { championStats },
  } = useSnapshot(readState);
  const searchParams = new URLSearchParams();
  if (patch) searchParams.set("patch", patch);
  const patchFilters = getDefaultedFiltersForChampions(
    searchParams,
    "overview",
  );
  const patchUrlParams = getSearchParamsForChampions(false, patchFilters);
  return championStats?.[patchUrlParams.toString()];
}

const useChampionsPatchChange = (patches, filterRole) => {
  const { t } = useTranslation();

  const currentPatchStats = usePatchStats();
  const currentPatch = currentPatchStats?.[0]?.patch;
  const previousPatch = patches[patches.indexOf(currentPatch) + 1];
  const prevPatchStats = usePatchStats(previousPatch);

  const filterRoleSymbol = RoleSymbol(filterRole);

  const champions = getStaticData("champions");

  const rows = useMemo(() => {
    if (
      !champions ||
      !currentPatchStats ||
      currentPatchStats instanceof Error ||
      !prevPatchStats ||
      prevPatchStats instanceof Error
    )
      return null;

    const urlSearchParams = new URLSearchParams();

    const stats = currentPatchStats
      .map((champStats) => {
        const { championId, role, tierListTier } = champStats;

        const championKey = champions?.keys?.[championId];
        const champion = champions?.[championKey];

        if (!champion || !championKey) return null;

        const prevChampStats = prevPatchStats.find(
          (champ) => champ.championId === championId && champ.role === role,
        );

        if (!prevChampStats) return null;

        const linkParams = new URLSearchParams(urlSearchParams);
        linkParams.set("role", ROLE_SYMBOL_TO_STR[role]?.gql);

        const championName = champion.name;
        const RoleIcon = role && getRoleIcon(role);

        const tier = tierListTier?.tierRank || 5;
        const TierIcon = getTierIcon(tier);

        const wr = calcRate(champStats.wins, champStats.games);
        const wrPrev = calcRate(prevChampStats.wins, prevChampStats.games);

        // keep any increase in winrate no matter how small,
        // just to ensure we have decent results
        if (wr < wrPrev) return null;

        return [
          {
            display: RoleIcon ? (
              <RoleIcon style={{ height: 24, width: 24 }} />
            ) : null,
            value: role,
          },
          {
            display: (
              <>
                <ChampionImg id={championId} size={28} />
                <span className="champion-name">{championName}</span>
              </>
            ),
            value: championName,
            className: "champion-meta",
            link: `/${lolRefs.lolChampionPrefix}/${championKey}/build?${linkParams}`,
          },
          { display: <TierIcon />, value: tier * -1 },
          {
            display: getLocaleString(wr, percentOptions),
            value: wr,
          },
          {
            display: getLocaleString(wr - wrPrev, percentOptions),
            value: wr - wrPrev,
          },
        ];
      })
      .filter(Boolean)
      .reduce((acc, curr) => {
        // Keep track of biggest change per role, per tier
        const role = curr[0].value;
        const change = curr[4].value;
        let tier = curr[2].value * -1;

        // Treat S/A the same
        if (tier === 2) tier = 1;

        if (!acc[tier]) acc[tier] = {};
        if (!acc[tier][role] || change > acc[tier][role].value) {
          acc[tier][role] = curr;
          acc[tier][role].value = change;
        }

        return acc;
      }, {});

    return [
      ROLE_SYMBOLS.top,
      ROLE_SYMBOLS.jungle,
      ROLE_SYMBOLS.mid,
      ROLE_SYMBOLS.adc,
      ROLE_SYMBOLS.support,
    ].map((role) => {
      // Prefer higher tiers
      const val =
        stats?.[1]?.[role] ||
        stats?.[3]?.[role] ||
        stats?.[4]?.[role] ||
        stats?.[5]?.[role];
      return val;
    });
  }, [currentPatchStats, prevPatchStats, champions]);

  const filteredData = useMemo(() => {
    if (!rows) return null;
    return rows
      .filter((v) => {
        if (!filterRoleSymbol) return true;
        const champRoleSymbol = v[0].value;
        const isRole = champRoleSymbol === filterRoleSymbol;
        return isRole;
      })
      .sort((a, b) => {
        return ROLE_INDEX[a[0].value] - ROLE_INDEX[b[0].value];
      });
  }, [rows, filterRoleSymbol]);

  return {
    cols: [
      { display: t("lol:role", "Role"), align: "center" },
      {
        display:
          currentPatch && previousPatch
            ? `${previousPatch} → ${currentPatch}`
            : t("lol:champion", "Champion"),
        align: "left",
        primary: true,
      },
      { display: t("common:tier", "Tier"), align: "center" },
      {
        display: t("common:winRate", "Win Rate"),
        align: "right",
        isStat: true,
      },
      {
        display: t("common:change", "Change"),
        align: "right",
        isStat: true,
      },
      // {
      //   display: t("common:banRate", "Ban Rate"),
      //   align: "right",
      //   isStat: true,
      // },
      // {
      //   display: t("common:pickRate", "Pick Rate"),
      //   align: "right",
      //   isStat: true,
      // },
      // { display: t("common:matches", "Matches"), align: "right", isStat: true },
    ],
    rows: filteredData,
  };
};

export default useChampionsPatchChange;
